import { useState } from 'react'
import { useLayout } from 'hooks/useLayout'
import { type LicensedEstablishment } from 'types/api'
import { Box, Grid, Typography } from '@mui/material'
import { SimpleImageCard } from 'components/ImageCard/SimpleImageCard'
import { UploadImageLicensedEstablishment } from './UploadImageLicensedEstablishment'
import { SectionHeader } from 'components/SectionHeader'
import { useSnackbar } from 'stores/useSnackbar'
import { usePutRemoveLicensedEstablishmentLogo } from 'hooks/api/LicensedEstablishment/usePutRemoveLicensedEstablishmentLogo'

export const ThumbnailDetails = ({
  licensedEstablishment,
}: {
  licensedEstablishment: LicensedEstablishment
}) => {
  const { isMobile } = useLayout()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const setMessage = useSnackbar((state) => state.setMessage)

  const toggleModalIsOpen = async () => {
    setIsModalOpen(!isModalOpen)
  }

  const propsBoxDevice = isMobile
    ? {}
    : {
        rowGap: '16px',
        borderWidth: 1,
        borderColor: 'grey.300',
        borderRadius: 2,
        px: '16px',
        py: '16px',
      }

  const { mutate: putRemoveLicensedEstablishmentLogoMutation } =
    usePutRemoveLicensedEstablishmentLogo({
      licensedEstablishmentId: licensedEstablishment.id,
      onSuccess: () => {
        setMessage(
          `${licensedEstablishment.standardName} has been updated`,
          'success',
          {
            vertical: 'bottom',
            horizontal: 'left',
          }
        )
      },
      onError: () => {
        setMessage('Error removing logo', 'error', {
          vertical: 'bottom',
          horizontal: 'left',
        })
      },
    })

  const onRemoveLogo = () => {
    putRemoveLicensedEstablishmentLogoMutation({
      ...licensedEstablishment,
      imageFileName: null,
    })
  }

  return (
    <>
      <SectionHeader
        title="Logo"
        buttonText="Add Image"
        onClickButton={toggleModalIsOpen}
        showSecondaryButton={!!licensedEstablishment.publicImageUrl}
        secondaryButtonText="Remove Image"
        onClickSecondaryButton={onRemoveLogo}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          ...propsBoxDevice,
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={12} md={12} sx={{ pt: { xs: 0, sm: 0 } }}>
            <Typography variant="body-3" className="w-full">
              Location Logo
            </Typography>
            <SimpleImageCard
              imageSource={licensedEstablishment.publicImageUrl}
              sx={{ maxWidth: '358px', maxHeight: '358px' }}
            />
          </Grid>
        </Grid>
      </Box>
      <UploadImageLicensedEstablishment
        isModalOpen={isModalOpen}
        toggleModalIsOpen={toggleModalIsOpen}
        licensedEstablishment={licensedEstablishment}
      />
    </>
  )
}
