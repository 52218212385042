import { first } from 'lodash'
import { useLayout } from 'hooks/useLayout'
import { object, string, number } from 'yup'
import { Modal } from 'components/Modal/Modal'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { ActivityButton } from 'components/ActivityButton'
import { isPresent } from '@jjvgaming/player-payback-library'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { type EditLicensedEstablishmentDetailsProps } from '../types'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import {
  Alert,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import {
  latitudeSchema,
  longitudeSchema,
} from 'utils/schemas/latitudeAndLongitudeSchemas'

const LicensedEstablishmentAddressDetailsSchema = object({
  Address: object({
    address1: string().required('Address 1 is a required field'),
    address2: string(),
    city: string().required('City is a required field'),
    state: string().required('State is a required field'),
    postalCode: number()
      .typeError('ZIP is a required field and must be a number')
      .test(
        'is-positive-or-zero',
        'Value must be a positive number',
        (value) => {
          return value === 0 || (typeof value === 'number' && value > 0)
        }
      )
      .transform((val) => (isNaN(val) ? undefined : val))
      .min(10000, 'ZIP must be greater than or equal to 10000')
      .max(99999, 'ZIP must be less than or equal to 99999')
      .required('ZIP is a required field'),
    latitude: latitudeSchema,
    longitude: longitudeSchema,
    geoRadius: number().required(),
  }),
})

export const EditLicensedEstablishmentAddressDetails = ({
  licensedEstablishment,
  updateIsActive,
  isModalOpen,
  toggleModalIsOpen,
  onSubmitLicensedEstablishment,
}: EditLicensedEstablishmentDetailsProps) => {
  const { isMobile } = useLayout()
  const heightModal = isMobile ? '100%' : '75%'

  const formMethods = useForm({
    resolver: yupResolver(LicensedEstablishmentAddressDetailsSchema),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods

  const handleSubmitWrapper = handleSubmit((formData) => {
    const existingAddressId = licensedEstablishment.addresses?.[0]?.id
    const addressData = formData.Address
    const address = {
      id: existingAddressId,
      address1: addressData.address1,
      address2: addressData.address2,
      city: addressData.city,
      state: addressData.state,
      country: 'US',
      postalCode: String(addressData.postalCode),
      geoRadius: addressData.geoRadius,
    }

    onSubmitLicensedEstablishment({
      ...licensedEstablishment,
      latitude: addressData.latitude,
      longitude: addressData.longitude,
      geoRadius: addressData.geoRadius,
      addresses: [address],
    })
  })

  const address = first(licensedEstablishment.addresses)

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={() => {}}
      sx={{
        width: isMobile ? '100%' : '55%',
        height: heightModal,
      }}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmitWrapper} noValidate>
          <ModalFullHeader
            toggleIsOpen={toggleModalIsOpen}
            title="Establishment Address"
            subTitle={`Licensed Establishment "${licensedEstablishment.standardName}"`}
          />
          <ModalFullBody heightModal={heightModal}>
            {errors.Address && (
              <Grid item xs={12} pb={4}>
                <Alert severity="error">
                  Please correct errors to continue
                </Alert>
              </Grid>
            )}

            <Grid container>
              <Grid item xs={12} md={6} pt={{ md: 2 }}>
                <InputLabel
                  required
                  htmlFor="Address.address1"
                  sx={{ fontSize: '16px', fontWeight: '100' }}
                >
                  Address Line 1
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  {...register('Address.address1', {
                    required: true,
                  })}
                  defaultValue={`${address?.address1}`}
                  error={isPresent(errors.Address?.address1)}
                />
                {errors.Address?.address1?.message && (
                  <FormFieldErrorMessage
                    message={errors.Address?.address1.message}
                  />
                )}
              </Grid>
            </Grid>

            <Grid container pt={4}>
              <Grid item xs={12} md={6} pt={{ md: 2 }}>
                <InputLabel
                  htmlFor="Address.address2"
                  sx={{ fontSize: '16px', fontWeight: '100' }}
                >
                  Address Line 2
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  {...register('Address.address2')}
                  defaultValue={`${address?.address2}`}
                  error={isPresent(errors.Address?.address2)}
                  placeholder="Enter address 2"
                />
                {errors.Address?.address2?.message && (
                  <FormFieldErrorMessage
                    message={errors.Address?.address2.message}
                  />
                )}
              </Grid>
            </Grid>

            <Grid container pt={4}>
              <Grid item xs={12} md={6} pt={{ md: 2 }}>
                <InputLabel
                  required
                  htmlFor="Address.city"
                  sx={{ fontSize: '16px', fontWeight: '100' }}
                >
                  City
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  {...register('Address.city', {
                    required: true,
                  })}
                  defaultValue={`${address?.city}`}
                  error={isPresent(errors.Address?.city)}
                />
                {errors.Address?.city?.message && (
                  <FormFieldErrorMessage
                    message={errors.Address?.city.message}
                  />
                )}
              </Grid>
            </Grid>

            <Grid container pt={4}>
              <Grid item xs={12} md={6} pt={{ md: 2 }}>
                <InputLabel
                  required
                  htmlFor="Address.state"
                  sx={{ fontSize: '16px', fontWeight: '100' }}
                >
                  State
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  {...register('Address.state', {
                    required: true,
                  })}
                  defaultValue={`${address?.state}`}
                  error={isPresent(errors.Address?.state)}
                />
                {errors.Address?.state?.message && (
                  <FormFieldErrorMessage
                    message={errors.Address?.state.message}
                  />
                )}
              </Grid>
            </Grid>

            <Grid container pt={4}>
              <Grid item xs={12} md={6} pt={{ md: 2 }}>
                <InputLabel
                  required
                  htmlFor="Address.postalCode"
                  sx={{ fontSize: '16px', fontWeight: '100' }}
                >
                  ZIP
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  {...register('Address.postalCode', {
                    required: true,
                  })}
                  defaultValue={`${address?.postalCode}`}
                  error={isPresent(errors.Address?.postalCode)}
                />
                {errors.Address?.postalCode?.message && (
                  <FormFieldErrorMessage
                    message={errors.Address?.postalCode.message}
                  />
                )}
              </Grid>
            </Grid>

            <Grid container pt={4}>
              <Grid item xs={12} md={6} pt={{ md: 2 }}>
                <InputLabel
                  htmlFor="Address.latitude"
                  sx={{ fontSize: '16px', fontWeight: '100' }}
                >
                  Latitude
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  {...register('Address.latitude')}
                  defaultValue={licensedEstablishment.latitude}
                  error={isPresent(errors.Address?.latitude)}
                />
                {errors.Address?.latitude?.message && (
                  <FormFieldErrorMessage
                    message={errors.Address?.latitude.message}
                  />
                )}
              </Grid>
            </Grid>

            <Grid container pt={4}>
              <Grid item xs={12} md={6} pt={{ md: 2 }}>
                <InputLabel
                  htmlFor="Address.longitude"
                  sx={{ fontSize: '16px', fontWeight: '100' }}
                >
                  Longitude
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  {...register('Address.longitude')}
                  defaultValue={licensedEstablishment.longitude}
                  error={isPresent(errors.Address?.longitude)}
                />
                {errors.Address?.longitude?.message && (
                  <FormFieldErrorMessage
                    message={errors.Address?.longitude.message}
                  />
                )}
              </Grid>
            </Grid>
            <Grid container pt={4}>
              <Grid item xs={12} md={6} pt={{ md: 2 }}>
                <InputLabel
                  htmlFor="Address.geoRadius"
                  sx={{ fontWeight: 'normal' }}
                >
                  Geo Radius (in meters)
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  fullWidth
                  placeholder="GeoRadius"
                  {...register('Address.geoRadius')}
                  defaultValue={licensedEstablishment?.geoRadius}
                  error={isPresent(errors.Address?.geoRadius)}
                >
                  <MenuItem value="5">5</MenuItem>
                  <MenuItem value="10">10</MenuItem>
                  <MenuItem value="20">20</MenuItem>
                  <MenuItem value="30">30</MenuItem>
                  <MenuItem value="40">40</MenuItem>
                  <MenuItem value="50">50</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </ModalFullBody>
          <ModalFullFooter>
            <Button variant="text" onClick={toggleModalIsOpen}>
              Cancel
            </Button>
            <ActivityButton
              active={updateIsActive}
              type="submit"
              disabled={!!errors.Address}
              variant="contained"
            >
              Save
            </ActivityButton>
          </ModalFullFooter>
        </form>
      </FormProvider>
    </Modal>
  )
}
