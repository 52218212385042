import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { type LicensedEstablishment } from 'types/api'
import { isPresent } from '@jjvgaming/player-payback-library'

const path = '/admin/corporate-accounts/licensed-establishments/{id}/logo'
const method = 'put'

interface UsePutLicensedEstablishmentParams {
  licensedEstablishmentId: number
  onSuccess?: (le: LicensedEstablishment) => void
  onError?: () => void
}

type WritableLicensedEstablishment = LicensedEstablishment & {
  id: number
  corporateAccountId: number
}

const isLicensedEstablishmentWritable = (
  le: LicensedEstablishment
): le is WritableLicensedEstablishment => {
  return isPresent(le.id) && isPresent(le.corporateAccountId)
}

export const usePutRemoveLicensedEstablishmentLogo = ({
  licensedEstablishmentId,
  onSuccess,
  onError,
}: UsePutLicensedEstablishmentParams) => {
  const queryClient = useQueryClient()
  const put = useTypedApiClient({ path, method })
  const mutate = useMutation({
    mutationKey: [
      '/admin/corporate-accounts/licensed-establishments/{id}',
      licensedEstablishmentId,
    ],
    mutationFn: async (le: LicensedEstablishment) => {
      if (isLicensedEstablishmentWritable(le)) {
        const { data } = await put(le)
        return data
      } else {
        throw new Error(
          'Cannot write LicensedEstablishment without required keys: id, corporateAccount'
        )
      }
    },
    onSuccess: async (le: LicensedEstablishment) => {
      await queryClient.invalidateQueries({
        queryKey: [
          `/admin/corporate-accounts/licensed-establishments/{id}`,
          licensedEstablishmentId,
        ],
      })
      onSuccess?.(le)
    },
    onError,
  })

  return mutate
}
