import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface useRewardsProgramTransferPointsProps {
  transferFromRewardsProgramId: number
  transferToRewardsProgramId: number
  onSuccess?: (data: boolean, variables: TransferPointsVariables) => void
  onError?: (error: Error, variables: TransferPointsVariables) => void
}

interface TransferPointsVariables {
  transferFromRewardsProgramId: number
  transferToRewardsProgramId: number
}

export const useRewardsProgramTransferPoints = ({
  transferFromRewardsProgramId,
  transferToRewardsProgramId,
  onSuccess,
  onError,
}: useRewardsProgramTransferPointsProps) => {
  const put = useTypedApiClient({
    path: '/admin/rewards-programs/transfer-points/{transferFromRewardsProgramId}/{transferToRewardsProgramId}',
    method: 'put',
  })
  const queryClient = useQueryClient()

  return useMutation<any, Error, TransferPointsVariables>({
    mutationFn: async () => {
      const response = await put({
        transferFromRewardsProgramId,
        transferToRewardsProgramId,
      })
      return response.data
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: ['/admin/rewards-programs'],
      })
      onSuccess?.(data, variables)
    },
    onError,
  })
}
